<template>
    <div class="tabmenudemo-content mb-2">
        <div class="grid p-fluid" >
            <div class="col-12 p-2">
                <Menu :slug="slug" :active="active" />
            </div>
        </div>
        <div v-if="message">
            <Message severity="error">{{message}}</Message>
        </div>
        <div class="card -m-b-2" v-if="tour">
            <Dialog header="Crop Image" v-model:visible="displayModal" :style="{width: '35vw'}" :modal="true">
                <cropper
                    class="upload-example-cropper"
                    :src="img.src"
                    :stencil-props="{
                        aspectRatio: 1.91/1
                    }"
                    ref="cropper"
                />
                <template #footer>
                <Divider></Divider>
                    <Button label="No" icon="pi pi-times" @click="closeModal" class="p-button-text"/>
                    <Button label="Crop" icon="pi pi-check" @click="crop" :loading="imageLoading" autofocus />
                </template>
            </Dialog>
            <Toast />
            <div class="grid">
                <Card style="width: 32.5%" class="col:lg-4 p-1 m-1 img-container" v-for="(image, myindex) of images" :key="myindex">
                    <template #header>
                        <img v-if="image.status == 'removed'" class="card-img" src="../../../assets/images/placeholder.png" :alt="image.name">
                        <img v-else-if="(image.src.indexOf(`:`)) != 4" class="card-img" :src="constant.imgUrl+image.src" :alt="image.name" role="presentation">
                        <img v-else class="card-img" :src="image.src"/>
                    </template>
                    <template #content>
                        <p>Picture No: {{myindex+1}}</p>
                    </template>
                    <template #footer>
                        <div class="flex flex-column">
                            <div class="flex right-auto">
                                <div class="flex flex-1">
                                    <input type="file" id="upload" hidden @change="imageSelected1"/>
                                    <label class="p-button" for="upload" @click="imgIndex(myindex, image.position)">Change File</label>
                                </div>
                                <div class="flex flex-1 ml-0" v-if="image.position != '1'">
                                    <Button v-if="image.status != 'removed'" icon="pi pi-times" label="Remove" class="p-button-secondary" @click="remove(myindex, image.position)"/>
                                </div>
                            </div>
                        </div>
                    </template>
                </Card>
                <Card style="width: 32%; float: right" class="col:lg-4 p-1 m-1 " v-if="(images.length-4) < 0" >
                        <template #header>
                            <img class="card-img" src="../../../assets/images/placeholder.png" alt="Add File">
                        </template>
                        <template #content>
                            <p>Picture No: {{images.length+1}}</p>
                        </template>
                        <template #footer>
                            <div>
                                <input type="file" id="upload" hidden @change="imageSelected1"/>
                                <label class="p-button-raised p-button w-full align-items-center justify-content-center" for="upload" @click="imgIndex(images.length, images.length+1)">Add File</label>
                            </div>
                        </template>
                </Card>
                <Button label="Update Images" icon="pi pi-check" :loading="loading" @click="updateImages()" class="p-button-outlined mt-4" style="width: 80%; margin: auto; display: block;" />
            </div>
        </div>
        <div v-if="sizeError">
            <Message severity="error">{{sizeError}}</Message>
        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity';
import { useRoute } from 'vue-router';
import { useToast } from 'primevue/usetoast';
import Axios from 'axios';
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
import { useRouter } from 'vue-router';
import Constant from '../../../data/constant';
import Menu from './TabMenu.vue';

export default {
    components: {
		Cropper,
        Menu
	},
    setup() {
        const toast = useToast();
        const router = useRouter();
        const route = useRoute();

        const constant = ref(new Constant());
        const cropper = ref(null);

        const slug = ref();
        slug.value = route.params.slug;
        const active = ref(4);

        const loading = ref(false);
        const loadingPage = ref(false);
        
        const tour = ref();
        const images = ref([]);
        const image = ref();
        const imageLoading = ref();

        const message = ref();
        const sizeError = ref();
        const imageSizeTotal = ref(0);
        
        const first_image = ref(null);
        const second_image = ref(null);
        const third_image = ref(null);
        const fourth_image = ref(null);

        const new_image = ref(null);
        const currentIndex = ref();
        const currentPosition = ref();

        const displayModal = ref(false);
        const img = ref(null);

        const getSingleTour = () => {
            Axios
            .get("api/tour/view/"+slug.value)
            .then((res) => {
                loadingPage.value = false;
                if(res.data.response=="success") {
                    tour.value = res.data.data;
                    if(tour.value.first_image != null){
                        images.value.push({
                            src: tour.value.first_image,
                            alt: "First image",
                            position: 1
                        });
                    }
                    if(tour.value.second_image != null){
                        images.value.push({
                            src: tour.value.second_image,
                            alt: "Second image",
                            position: 2
                        });
                    }
                    if(tour.value.third_image != null){
                        images.value.push({
                            src: tour.value.third_image,
                            alt: "Third image",
                            position: 3
                        });
                    }
                    if(tour.value.fourth_image != null){
                        images.value.push({
                            src: tour.value.fourth_image,
                            alt: "Fourth image",
                            position: 4
                        });
                    }                    
                }
                else {
                    console.log(res.data.message);
                }
            })
            .catch((err) => {
                console.log(err);
            });
        }
        getSingleTour();

        const closeModal = () => {
            displayModal.value = false;
        }

        const dragFile = (event) => {
            img.value = event.dataTransfer.files[0];
            img.value.src = URL.createObjectURL(event.dataTransfer.files[0]);
            displayModal.value = true;

            if (img.value.size < 8*1048576) {
                displayModal.value = true;
            }else{
                message.value = 'Maximum size of the picture can be 8 MB. This picture size is '+ (img.value.size/1048576).toFixed(2) +'MB';
            }
        };

        const imageSelected1 = (event) =>{
            img.value = event.target.files[0];
            img.value.src = URL.createObjectURL(event.target.files[0]);
            displayModal.value = true;

            if (img.value.size < 8*1048576) {
                displayModal.value = true;
            }else{
                message.value = 'Maximum size of the picture can be 8 MB. This picture size is '+ (img.value.size/1048576).toFixed(2) +'MB';
            }
        };

        const imgIndex = (myindex, position) =>{
            currentIndex.value = myindex;
            currentPosition.value = position;
        };

        const remove = (index, position) =>{
            const removeValue = ref([]);
            removeValue.value.position = position;
            removeValue.value.status = 'removed';
            images.value.splice(index, 1, removeValue.value)
        };

        const crop = () => {
            const { canvas } = cropper.value.getResult();
            imageLoading.value = true;

            if (canvas) {
				canvas.toBlob(blob => {
                    
					image.value = blob;
                    image.value.src = URL.createObjectURL(blob);
                    image.value.position = currentPosition.value;

                    images.value.splice(image.value.position-1, 1, image.value);
                    imageSizeTotal.value = imageSizeTotal.value + image.value.size;
                    imageLoading.value = false;
                    displayModal.value = false;

                    if (imageSizeTotal.value > 5*1048576 && imageSizeTotal.value <= 8*1048576) {
                        sizeError.value = 'Your picture(s) size is big ('+ (imageSizeTotal.value/1048576).toFixed(2) +'MB). It will take a bit more time to upload';
                    } else if (imageSizeTotal.value > 8*1048576 && imageSizeTotal.value <= 16*1048576) {
                        sizeError.value = 'Your picture(s) size is large ('+ (imageSizeTotal.value/1048576).toFixed(2) +'MB). It will take a bit long time to upload';
                    } else if (imageSizeTotal.value > 16*1048576 && imageSizeTotal.value <= 24*1048576) {
                        sizeError.value = 'Your picture(s) size is very large ('+ (imageSizeTotal.value/1048576).toFixed(2) +'MB). It will take long time to upload';
                    } else if (imageSizeTotal.value > 24*1048576){
                        sizeError.value = 'Your picture(s) size is huge ('+ (imageSizeTotal.value/1048576).toFixed(2) +'MB). It will take few munites to upload';
                    } else {
                        sizeError.value = '';
                    }

                    //browser-image-compression

                    // const options = ref({
                    //     maxSizeMB: (img.value.size/1048576).toFixed(2),
                    // });

                    // imageCompression(image.value, options.value)
                    //     .then(function(compressedFile) {
                    //         compressedFile.src = URL.createObjectURL(blob);
                    //         compressedFile.position = image.value.position;
                            
                    //         return uploader(compressedFile);
                    //     })

                    //end
                    
				});
			}
        };

        // const uploader = async (file) => {
        //     images.value.splice(file.position-1, 1, file)
        //     imageSizeTotal.value = imageSizeTotal.value + file.size;
        //     imageLoading.value = false;
        //     displayModal.value = false;

        //     if (imageSizeTotal.value > 5*1048576 && imageSizeTotal.value <= 8*1048576) {
        //         sizeError.value = 'Your picture(s) size is big ('+ (imageSizeTotal.value/1048576).toFixed(2) +'MB). It will take a bit more time to upload';
        //     } else if (imageSizeTotal.value > 8*1048576 && imageSizeTotal.value <= 16*1048576) {
        //         sizeError.value = 'Your picture(s) size is large ('+ (imageSizeTotal.value/1048576).toFixed(2) +'MB). It will take a bit long time to upload';
        //     } else if (imageSizeTotal.value > 16*1048576 && imageSizeTotal.value <= 24*1048576) {
        //         sizeError.value = 'Your picture(s) size is very large ('+ (imageSizeTotal.value/1048576).toFixed(2) +'MB). It will take long time to upload';
        //     } else if (imageSizeTotal.value > 24*1048576 && imageSizeTotal.value <= 32*1048576){
        //         sizeError.value = 'Your picture(s) size is huge ('+ (imageSizeTotal.value/1048576).toFixed(2) +'MB). It will take few munites to upload';
        //     } else {
        //         sizeError.value = '';
        //     }
        // };

        const showToast = (severity, summary, detail) => {
            toast.add({severity: severity, summary: summary, detail: detail, life: 5000});
        };

        const backToDetails = () => {
            router.replace({ name: 'details', params: {slug: slug.value}});
        };

        const updateImages = () =>{
            const formData = new FormData();

            loading.value = true;

            for(var i = 0; i<images.value.length; i++){
                if(images.value[0]){
                    if (images.value[0].status == 'removed' || images.value[0].src.indexOf(`:`) == 4) {
                        first_image.value = images.value[0];
                    }
                }
                if(images.value[1]){
                    if (images.value[1].status == 'removed' || images.value[1].src.indexOf(`:`) == 4) {
                        second_image.value = images.value[1];
                    }
                }
                if(images.value[2]){
                    if (images.value[2].status == 'removed' || images.value[2].src.indexOf(`:`) == 4) {
                        third_image.value = images.value[2];
                    }
                }
                if(images.value[3]){
                    if (images.value[3].status == 'removed' || images.value[3].src.indexOf(`:`) == 4) {
                        fourth_image.value = images.value[3];
                    }
                }
            }
            if (first_image.value) {
                if (first_image.value.status == 'removed') {
                    first_image.value = null;
                }
                formData.append("first_image", first_image.value);
            }
            if (second_image.value) {
                if (second_image.value.status == 'removed') {
                    second_image.value = '';
                }
                formData.append("second_image", second_image.value);
            }
            if (third_image.value) {
                if (third_image.value.status == 'removed') {
                    third_image.value = '';
                }
                formData.append("third_image", third_image.value);
            }
            if (fourth_image.value) {
                if (fourth_image.value.status == 'removed') {
                    fourth_image.value = '';
                }
                formData.append("fourth_image", fourth_image.value);
            }
            
            formData.append("_method", "PUT");

            Axios
            .post(`api/operator/tour/update-images/${tour.value.id}`, formData, {
                config: {
                    headers: {'Content-Type': 'multipart/form-data' }
                }
            })
            .then((res) => {
                loading.value = false;
                if(res.data.response=="success") {
                    router.replace({name: 'u-pictures', params:{slug: res.data.data.slug}});
                    showToast("success", "Success", res.data.message);
                }
                else {
                    loading.value = false;
                    showToast("error", "Error", res.data.message);
                    console.log(res.data.message);
                }
            })
            .catch((err) => {
                loading.value = false;
                console.log(err);
                showToast("error", "Error", "Something went wrong. Try again!");
            });
        };

        return{
            slug,
            active,
            tour,
            constant,

            backToDetails,
            message,
            sizeError,
            imageSizeTotal,
            crop,
            cropper,

            img,
            image,
            images,
            new_image,
            loading,
            loadingPage,
            imageLoading,

            first_image,
            second_image,
            third_image,
            fourth_image,

            currentIndex,
            currentPosition,

            closeModal,
            displayModal,

            dragFile,
            imageSelected1,
            remove,
            updateImages,
            imgIndex,
        }
    }
}
</script>

<style lang="scss" scoped>
    .card{
        margin-bottom: 0px !important;
    }
    .card-img{
        width: 100%;
        max-height: 32vh;
    }
    .img-container:hover .card-img{
        transform: scale(1.05);
    }
    .cropper {
        background: #DDD;
    }
</style>